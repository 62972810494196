<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title : "Новый документ '" + m.title + "'" }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    m: Object,
    hasData: { type: Boolean, default: false },
  },
  data() {
    return {
      removeDialogShow: false,
      idEdit: 0,
      suEditShow: false,
      modelDoc: null,
      modelReady: false,
      type: null,
      loaded: false,
      options: [],
      model: [],
    };
  },
  computed: {
    editObject() {
      return false;
    },
    operations() {
      let res = this.$root.dirs.operationTable;
      return res.filter((o) => this.options.includes(o.value));
    },
  },
  created() {
    this.loaded = false;
    this.type = this.initType || null;
    if (this.$root.dirs.operationTable) {
    }
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    "data.type"(v) {
      this.type = v;
      this.getModel(v);

      if (this.loaded) {
        if (v == 1) {
          this.data.object_code = parseInt(this.data.object_code) ? parseInt(this.data.object_code) : null;
        }
        if (v !== 1) {
          this.data.object_code = null;
        }
        if (v !== 2) this.data.office_id = null;
        if (v !== 3) this.data.store_id = null;
      }
    },
  },

  methods: {
    getModel(t) {
      let type = t || this.initType || 0;
      console.log("getModel", type);
      let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
      if (this.data.status == 2) config.fieldsRO = config.fields;
      let model = this.calcModelConfig(config);
      if (!false)
        model.forEach((el) => {
          if (this.hasData) {
            if (!"vendor_id".split(",").includes(el.name)) {
              el.readonly = true;
            }
          }
          if (this.id && el.name == "date_doc") {
            el.hidden = !true;
          }
          if (el.name == "operation_type") {
            this.options = this.m.operations[type];
            el.options = this.operations; // dirFilter = { value: this.m.operations[type] || [] };
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
    },
    async beforeSave() {
      if (!this.options.includes(this.data.operation_type)) {
        this.data.operation_type = null;
      }
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.type);
      this.getModel(this.data?.type);
      this.loaded = true;
    },
  },
};
</script>
